import Card from "../../bulma_components/Card.js";
import ExpandableContainer from "../../bulma_components/ExpandableContainer.js";
import ButtonToggler from "../../bulma_components/ButtonToggler.js";
import {extendComponent, joinComponents} from "juis-components/ComponentUtils.js";
import Checkbox from "../../bulma_components/Checkbox.js";
import {createHorizontalFormField} from "../fieldEditors/editorFactories.js";
import Button from "../../bulma_components/Button.js";
import {notificationEvents} from "../notification/Notification.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import TagSelect from "../dropdown/TagSelect.js";
import TextComponent from "../TextComponent.js";
import Linguist from "../../../lib/JuiS/Linguist.js";
import tableViewLexicons from "./lexicons/tableViewLexicons.js";
import DynamicField from "../../hewecon/models/DynamicField.js";
import PromiseButton from "../PromiseButton";

let ExpandableCard = joinComponents(Card, ExpandableContainer);

const PDF_COUNT_LIMIT = 1000;
const EXCEL_LIMIT = 10000;
const COUNT_LIMIT = 25000;
const mimeTypes = {
    HTML: "text/html",
    CSV: "text/csv",
    PDF: "application/pdf",
    EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
const fileTypes = [
    {name: "CSV", mimeType: mimeTypes.CSV, icon: "file-csv"},
    {name: "Excel", mimeType: mimeTypes.EXCEL, icon: "file-excel"},
    {name: "HTML", mimeType: mimeTypes.HTML, icon: "file-code"},
    {name: "PDF", mimeType: mimeTypes.PDF, icon: "file-pdf"},
];

let ExportCard = extendComponent(ExpandableCard, function () {
    let model;
    let exportSettings = {};
    let filter;
    let suggestionFilter;
    let queryParameters;
    let orderBy;
    let totalCount;
    let beforeExportCallback = () => {
    };
    this.setTitle("Export");
    let fileTypeInput = ButtonToggler(function () {
        fileTypes.forEach(type => this.addValue(type.mimeType, type.name, type.icon));
        this.getNode().setStyle("width", "9em");
    });
    let columnInput = new TagSelect(function () {
        this.multiple = true;
        this.setTagFactory(field => new TextComponent(field.getLabel()));
        this.setItemFactory(field => new TextComponent(field.getLabel()));
    });
    let includeHeadersInput = new Checkbox();
    includeHeadersInput.setValue(true);
    this.content.typeField = createHorizontalFormField("File type", fileTypeInput);
    this.content.columns = createHorizontalFormField("Columns", columnInput);
    this.content.includeHeaders = createHorizontalFormField("Include headers", includeHeadersInput);

    this.getColumns = function () {
        return columnInput.getValue();
    };

    let getColumnSettings = () => {
        let settings = {
            columns: [],
            columnNames: [],
            summedColumns: [],
        };
        this.getColumns().forEach(field => {
            let label = field.getLabel();
            if (field instanceof DynamicField) {
                field = field.getField(model);
            }
            if (field.getExportField) {
                field = field.getExportField();
            } else if (field.getModelType()) {
                field = field[field.getModelType().getExportField().getName()];
            }
            if (field.isVirtual()) {
                if (field.getOrderBy()) {
                    field = model[field.getOrderBy()];
                }
            }
            settings.columns.push(field.getName());
            if (includeHeadersInput.getValue()) {
                settings.columnNames.push(label);
            }
            if (field.isSummable()) {
                settings.summedColumns.push(field.getName());
            }
        });
        return settings;
    };

    this.setModel = (newModel) => model = newModel;
    this.setSelectableColumns = selectableColumns => selectableColumns.forEach(field => columnInput.addOption(field, field.getName()));
    this.setColumns = columns => columnInput.setValue(columns);
    this.setType = type => fileTypeInput.setValue(type);
    this.setExportSettings = newExportSettings => exportSettings = newExportSettings;
    this.setFilter = newFilter => filter = newFilter;
    this.setSuggestionFilter = newSuggestionFilter => suggestionFilter = newSuggestionFilter;
    this.setQueryParameters = newQueryParameters => queryParameters = newQueryParameters;
    this.setOrderBy = newOrderBy => orderBy = newOrderBy;
    this.setBeforeExport = newBeforeExportCallback => beforeExportCallback = newBeforeExportCallback;
    this.setTotalCount = (newTotalCount) => {
        totalCount = newTotalCount;
        this.setTitle(linguist.t("titleWithCount", {totalCount}));
    };

    const validateExport = (settings) => {
        if (typeof totalCount === "number" && settings.type === mimeTypes.PDF && totalCount > PDF_COUNT_LIMIT) {
            return Promise.reject(new Error(linguist.t("tooManyLinesPDF", {PDF_COUNT_LIMIT})));
        }
        if (typeof totalCount === "number" && settings.type === mimeTypes.EXCEL && totalCount > EXCEL_LIMIT) {
            return Promise.reject(new Error(linguist.t("tooManyLinesExcel", {EXCEL_LIMIT})));
        }
        if (typeof totalCount === "number" && totalCount > COUNT_LIMIT) {
            return Promise.reject(new Error(linguist.t("tooManyLines", {COUNT_LIMIT})));
        }
        if (settings.columns.length === 0) {
            return Promise.reject(new Error(linguist.t("noColumns")));
        }
        return Promise.resolve(settings);
    };

    this.export = () => {
        beforeExportCallback();
        let settings = {
            ...exportSettings,
            filter,
            suggestionFilter,
            orderBy,
            queryParameters,
            ...getColumnSettings(),
            type: fileTypeInput.getValue(),
        };
        return validateExport(settings).then(validSettings => model.export(validSettings));
    };

    this.footer.getNode().addCssClass("hewecon-card-foot");
    this.footer.cancelButton = new Button((button) => {
        button.text = "Cancel";
        button.on("click", () => {
            this.trigger("close");
        });
    });
    this.footer.exportButton = new PromiseButton((button) => {
        button.text = "Export";
        button.callback = () => this.export().catch(error => {
            this.trigger(notificationEvents.NOTIFY, {
                text: linguist.t("couldNotExport") + error.message,
                cssClass: BULMA_CSS_CLASSES.IS_DANGER
            });
        });
    }, [BULMA_CSS_CLASSES.IS_PRIMARY]);
    this.collapse();

    const linguist = new Linguist(tableViewLexicons).withBaseKey("exportCard").withAudience(this);
});

export default ExportCard;
