import Field from "../../utils/Field.js";
import Product from "./Product.js";
import Stock from "./Stock.js";
import {
    createSimpleField,
    createTextAreaEditor,
    getDropDownFactory,
    getPriceWithVatEditorFactory
} from "../../components/fieldEditors/editorFactories.js";
import App from "./App.js";
import Component from "juis-components/Component.js";
import Filter from "../../utils/Filter.js";
import InputWithSuggestions from "../../components/dropdown/InputWithSuggestions.js";
import StockProduct from "./StockProduct.js";
import Container from "juis-components/Container.js";
import BULMA_CSS_CLASSES from "../../bulma_components/bulmaCssClasses.js";
import HTML from "../../utils/HTML.js";
import SuggestionFilter from "../../utils/SuggestionFilter.js";
import TextComponent from "../../components/TextComponent.js";
import {createLink} from "../../bulma_components/Link.js";
import Method from "../../utils/Method.js";
import setDefaults from "./utils/setDefaults.js";

let hideIfTextItem = (value, billItem) => {
    if (billItem.textItem) {
        return "";
    }
    return value;
};

export default function () {
    this.app = new Field(App);
    this.product = new Field(Product)
        .withFilterGetter(entity => entity.getProductFilter())
        .withCellFactory((product, itemBase) => {
            if (!itemBase) {
                return "N/A";
            }
            const productNumber = itemBase.productNumber ?? product?.productNumber;
            const name = itemBase.name ?? product?.name;
            let text;
            if (productNumber && name) {
                text = productNumber + " · " + name;
            } else {
                text = name || productNumber || "[Unnamed]";
            }
            if (product && !product.deleted) {
                return createLink(product.getUrl(), text);
            } else {
                return new TextComponent(text);
            }
        })
        .withLabel("Product");
    this.quantity = new Field().asNumber().withLabel("Quantity")
        .asRequired()
        .withCellFactory((quantity, billItem) => {
            if (billItem && billItem.unit) {
                return quantity + " " + billItem.unit;
            }
            return quantity;
        });
    this.knownStockQuantities = new Field().asVirtual();
    this.reverseTransactions = new Field().asBoolean();
    this.stock = new Field(Stock)
        .withOptionsLoader((searchString, productItem, filter) => {
                if (!productItem.product) {
                    return Promise.resolve([]);
                }
                productItem.knownStockQuantities = {};
                const stockProductBasedFilter = filter?.rebase(StockProduct.stock);
                return StockProduct.getListForDropdown(
                    searchString,
                    [StockProduct.stock.name, StockProduct.stock.parent.name],
                    productItem.app,
                    Filter.eq(StockProduct.product, productItem.product).and(stockProductBasedFilter)
                ).then(stockProductList => {
                    let resultList = stockProductList.map(stockProduct => {
                        productItem.knownStockQuantities[stockProduct.stock.id] = stockProduct.quantity;
                        return stockProduct.stock;
                    });
                    if (resultList.length >= 10) {
                        return resultList;
                    }
                    let stockRequestSettings = {
                        maxRows: 10 - resultList.length,
                        lastPageMaxRows: 25 - resultList.length,
                        filter: Filter.and(Filter.eq(this.app, productItem.app), Filter.notIn(Stock, resultList)).and(filter)
                    };
                    if (searchString) {
                        const fields = [Stock.parent.name, Stock.name];
                        stockRequestSettings.suggestionFilter = new SuggestionFilter(searchString, fields, fields);
                    }

                    return Stock.getList(stockRequestSettings)
                        .then(response => response["data"])
                        .then(stockList => {
                            stockList.forEach(stock => resultList.push(stock));
                            return resultList;
                        });
                });
            }
        )
        .withLabel("From stock")
        .withEditorFactory(
            getDropDownFactory({
                searchable: true,
                refreshOnChange: [this.product, this.quantity],
                tagFactory: (stock) => Stock.getDefaultItemFactory()(stock),
                itemFactory: (stock, productItem) => {
                    if (!productItem.reverseTransactions && productItem.product) {
                        return new Container(function () {
                            this.name = new TextComponent(stock.getFullName(), HTML.SPAN,
                                BULMA_CSS_CLASSES.IS_INLINE_BLOCK);
                            let quantity = 0;
                            if (productItem.knownStockQuantities && productItem.knownStockQuantities[stock.id]) {
                                quantity = productItem.knownStockQuantities[stock.id];
                            }
                            let text = `${quantity} ${productItem.product.unit ?? ""}`;
                            this.available = new TextComponent(text, HTML.SPAN, [BULMA_CSS_CLASSES.TAG]);
                            if (productItem.quantity <= 0) {
                                this.available.getNode().addCssClass(BULMA_CSS_CLASSES.IS_INFO);
                            } else if (quantity < productItem.quantity) {
                                this.available.getNode().addCssClass(BULMA_CSS_CLASSES.IS_DANGER);
                            } else {
                                this.available.getNode().addCssClass(BULMA_CSS_CLASSES.IS_SUCCESS);
                            }
                            this.getNode().setStyle("display", "flex");
                            this.getNode().setStyle("justify-content", "space-between");
                        });
                    }
                    return Stock.getDefaultItemFactory()(stock, productItem);
                }
            }));
    this.productSuggestionFactory = (field, billItem, customFilter = Filter.true) => {
        const fieldName = field.getName();
        const input = new InputWithSuggestions();
        const emptyResults = [];
        input.setOptionsLoader((searchString) => {
            if (emptyResults.some(emptySearch => searchString.startsWith(emptySearch))) {
                return Promise.resolve([]);
            }
            const skipNulls = Filter.ne(Product[fieldName], null);
            return Product.getListForDropdown(searchString, Product.SEARCH_FIELDS, billItem.app,
                Filter.and(skipNulls, customFilter), field)
                .then(results => {
                    if (results.length === 0) {
                        emptyResults.push(searchString);
                    }
                    return results;
                });
        });
        if (billItem[fieldName]) {
            input.setValue(billItem[fieldName]);
        } else if (billItem.product) {
            input.setValue(billItem.product[fieldName]);
        }
        input.setSuggestionToTextMapper(product => product[fieldName]);
        input.setItemFactory(product => product[fieldName]);
        input.on("suggestionSelected", (product) => billItem.product = product);
        return createSimpleField(field, billItem, input);
    };
    this.productNumber = new Field().asString().withLabel("Product number").withEditorFactory(this.productSuggestionFactory);
    this.name = new Field().asString().withLabel("Name").withEditorFactory(this.productSuggestionFactory);
    this.description = new Field().asString().withLabel("Description")
        .withCellFactory((value, ticketProduct) => {
            return new Component(function () {
                this.getNode().setText(ticketProduct.description);
                this.getNode().setStyle("overflow", "hidden");
                this.getNode().setStyle("text-overflow", "ellipsis");
                this.getNode().setAttribute("title", ticketProduct.description);
            });
        })
        .withTableSettings({css: {"max-width": "20em"}})
        .withEditorFactory(createTextAreaEditor);
    this.unit = new Field().asString().withLabel("Unit").withCellFactory(hideIfTextItem);
    this.vatPercentage = new Field().asNumber(0, 100, 0.5)
        .withDefaultValueCallback(() => 0)
        .withLabel("VAT %");
    this.discountPercentage = new Field().asNumber(0, 100, 1)
        .withDefaultValueCallback(() => 0)
        .withLabel("Discount %");
    this.priceIncludesVat = new Field().asBoolean().withLabel("Price includes VAT");
    this.currencyCode = new Field().asCurrency();
    this.price = new Field().asMoney(this.currencyCode).withLabel("Price")
        .withDefaultValueCallback(() => 0)
        .withEditorFactory(getPriceWithVatEditorFactory(this.priceIncludesVat, this.vatPercentage, this.currencyCode));
    this.priceVatIncluded = new Field().asMoney(this.currencyCode).withLabel("Price").asReadOnly();
    this.priceVatExcluded = new Field().asMoney(this.currencyCode).withLabel("Price").asReadOnly();
    this.totalPriceVatIncluded = new Field().asMoney(this.currencyCode).withLabel("Total incl. VAT").asReadOnly();
    this.totalPriceVatExcluded = new Field().asMoney(this.currencyCode).withLabel("Total excl. VAT").asReadOnly();
    this.totalVat = new Field().asMoney(this.currencyCode).withLabel("Total VAT").asReadOnly();
    this.ordinal = new Field().asNumber().withLabel("Ordinal");
    this.grossWeight = new Field().asNumberWithUnit("kilogram").withLabel("Gross weight");
    this.netWeight = new Field().asNumberWithUnit("kilogram").withLabel("Net weight");
    this.packageWeight = new Field().asNumberWithUnit("kilogram").withLabel("Package weight");

    const productFieldMapper = {
        "price": "price.enteredPrice",
        "vatPercentage": "price.vatGroup.vatPercentage",
        "priceIncludesVat": "price.priceIncludesVat",
        "currencyCode": "price.currencyCode"
    };

    this.copyValues = function (source, productItemFields, mapper) {
        if (source.deleted) {
            return;
        }
        productItemFields
            .map(field => field.getName ? field.getName() : field)
            .filter(field => mapper(field) !== undefined)
            .forEach(field => this[field] = mapper(field));
        if (this.vatPercentage === null) {
            this.vatPercentage = 0;
        }
    };

    this.copyValuesFromProductAggregate = function (productAggregate, fields = [
        "productNumber",
        "name",
        "description",
        "unit",
        "price",
        "vatPercentage",
        "priceIncludesVat",
        "currencyCode"
    ]) {
        const getProductAggregateValue = field => productAggregate[field];
        this.copyValues(productAggregate, fields, getProductAggregateValue);
    };

    this.copyValuesFromProduct = function (product, productItemFields = [
        "productNumber",
        "name",
        "description",
        "unit",
        "price",
        "vatPercentage",
        "priceIncludesVat",
        "currencyCode"
    ]) {
        const getProductValue = field => productFieldMapper[field] ? product[productFieldMapper[field]] : product[field];
        this.copyValues(product, productItemFields, getProductValue);
        if (!this.packageWeight && product.defaultContainerWeight) {
            this.packageWeight = product.defaultContainerWeight;
        }
        if (productItemFields.includes("name") && product.variationOf) {
            this.name = product.variationOf.name + " " + product.name;
        }
    };

    this.copyValuesFromWorkLog = function (workLog) {
        if (workLog.product && !workLog.product.deleted) {
            this.copyValuesFromProduct(workLog.product);
        } else {
            this.vatPercentage = workLog.app.defaultVat.vatPercentage;
            this.priceIncludesVat = workLog.app.pricesIncludeVat;
            this.price = 0;
            this.currencyCode = workLog.app.currencyCode;
        }
        this.description = workLog.description;
        this.unit = "h";
        this.quantity = Math.round(workLog.duration / 36) / 100;
    };
    this.on([this.EVENTS.AFTER_INSERT, this.EVENTS.AFTER_UPDATE, this.EVENTS.AFTER_DELETE], (productItem) => {
        if (productItem.stock) {
            StockProduct.refreshQuantities(productItem.stock, productItem.product);
        }
    });

    // Override this method
    this.getProductFilter = new Method(function () {
        return Filter.true;
    });

    setDefaults(this, {defaultField: this.product.name});
}
